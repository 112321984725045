<template>
  <div>
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <date-period-selector
      ref="refDatePeriodSelector"
      :year-options="yearOptions"
      @saved="customPeriodSelected"
    />
    <b-card>

      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <b-card-header v-if="reportsData.salesByProduct">

          <div>

            <h5>{{ reportTotals.count }} Products</h5>
            <h1 class="font-weight-bolder">
              {{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(reportTotals.total) }}
            </h1>
          </div>
          <div>
            <b-form-group

              label="Time period"
              label-for="time-period"
            >

              <b-form-select
                id="time-period"
                v-model="filterData.date_type"
                :options="filterDateTypeOptions"
                size="lg"
              />
            </b-form-group>
            <div
              v-if="filterData.date_type=='custom_period'"
              class="d-flex flex-column align-items-center"
            >
              <small>{{ $moment.months(filterData.start_month - 1) }} {{ filterData.start_year }} - {{ $moment.months(filterData.end_month - 1) }} {{ filterData.end_year }} </small>
              <b-link @click="showDatePeriodSelector">
                Edit period
              </b-link>
            </div>
          </div>
        </b-card-header>
        <b-row v-if="reportsData.salesByProduct">

          <b-col md="12">
            <b-table
              foot-clone
              responsive
              :fields="tableColumns"
              :items="reportsData.salesByProduct.sales_by_product"
            >
              <template #cell(invoice_month)="data">
                <span> {{ $moment.months(data.item.invoice_month - 1) }}</span>
              </template>
              <template #cell(subtotal)="data">
                <span>{{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(data.item.subtotal) }}</span>
              </template>
              <template #cell(tax_total)="data">
                <span> {{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(data.item.tax_total) }}</span>
              </template>
              <template #cell(discount_total)="data">
                <span> {{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(data.item.discount_total) }}</span>
              </template>
              <template #cell(total)="data">
                <span> {{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(data.item.total) }}</span>
              </template>
              <template #cell(paid_amount)="data">
                <span>{{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(data.item.paid_amount) }}</span>
              </template>

              <!-- TABLE FOOTER -->
              <template v-slot:foot(product_name)="">
                <span>Total</span>
              </template>
              <template v-slot:foot(unit_name)="" />
              <template v-slot:foot(quantity)="">
                <span>{{ reportTotals.quantity }}</span>
              </template>
              <template v-slot:foot(subtotal)="">
                <span>{{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(reportTotals.subtotal) }}</span>
              </template>
              <template v-slot:foot(tax_total)="">
                <span>{{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(reportTotals.tax_total) }}</span>
              </template>
              <template v-slot:foot(discount_total)="">
                <span>{{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(reportTotals.discount_total) }}</span>
              </template>
              <template v-slot:foot(total)="">
                <span>{{ reportsData.salesByProduct.currency_code }} {{ $staticParams.formatCurrency(reportTotals.total) }}</span>
              </template>

              <!-- TABLE FOOTER END -->

            </b-table>
          </b-col>

        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BFormSelect, BOverlay, BSpinner, BTable, BFormGroup, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required, alphaNum, email } from '@validations'
import datePeriodSelector from './DatePeriodSelector.vue'

export default {
  components: {
    BRow,
    BOverlay,
    BFormSelect,
    BFormGroup,
    BTable,
    BCard,
    BCardHeader,
    BLink,
    datePeriodSelector,
    BSpinner,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      filterData: {
        date_type: 'monthly',
        start_year: 0,
        end_year: 0,
        start_month: 0,
        end_month: 0,
      },
      currentYear: 0,
      currentMonth: 0,
      overlayMsg: 'Loading...',
      pageIsLoading: true,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Product Updated',
      errorData: null,
      yearOptions: [],
      blankFilterData: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        cost: 0,
        enable_taxes: true,
        unit_id: 0,
        currency_code: '',
      },
      reportsData: {
        salesByProduct: null,
      },
      filterDateTypeOptions: [
        { text: 'THIS MONTH', value: 'monthly' },
        { text: 'THIS YEAR', value: 'yearly' },
        { text: 'ALL TIMES', value: 'all_times' },
        { text: 'CUSTOM PERIOD', value: 'custom_period' },

      ],
      tableColumns: [
        { key: 'product_name', label: 'PRODUCT', sortable: true },
        { key: 'unit_name', label: 'UNIT TYPE', sortable: true },
        { key: 'quantity', label: 'QUANTITY', sortable: true },
        { key: 'subtotal', label: 'SUBTOTAL', sortable: true },
        { key: 'tax_total', label: 'TAX', sortable: true },
        { key: 'discount_total', label: 'DISCOUNT', sortable: true },
        { key: 'total', label: 'TOTAL', sortable: true },
      ],

    }
  },
  computed: {

    reportTotals() {
      const totals = {
        count: 0,
        total: 0,
        tax_total: 0,
        discount_total: 0,
        subtotal: 0,
        paid_amount: 0,
        quantity: 0,

      }
      if (this.reportsData.salesByProduct) {
        this.reportsData.salesByProduct.sales_by_product.forEach(val => {
          totals.total += val.total
          totals.count += val.count
          totals.tax_total += val.tax_total
          totals.discount_total += val.discount_total
          totals.subtotal += val.subtotal
          totals.paid_amount += val.paid_amount
          totals.quantity += val.quantity
        })
      }
      return totals
    },
  },
  watch: {
    'filterData.date_type': {

      handler(val) {
        if (val === 'monthly') {
          this.filterData.start_year = this.currentYear
          this.filterData.start_month = this.currentMonth
          this.filterData.end_year = this.currentYear
          this.filterData.end_month = this.currentMonth
        } else if (val === 'yearly') {
          this.filterData.start_year = this.currentYear
          this.filterData.start_month = null
          this.filterData.end_year = this.currentYear
          this.filterData.end_month = null
        } else if (val === 'all_times') {
          this.filterData.start_year = null
          this.filterData.start_month = null
          this.filterData.end_year = null
          this.filterData.end_month = null
        } else if (val === 'custom_period') {
          this.filterData.start_year = this.currentYear
          this.filterData.start_month = this.currentMonth
          this.filterData.end_year = this.currentYear
          this.filterData.end_month = this.currentMonth
          this.showDatePeriodSelector()
        }

        if (val !== 'custom_period') {
          this.fetchReports()
        }
      },
    },
  },
  created() {
    this.filterData.currency_code = this.$user().currency.currency_code
    // eslint-disable-next-line no-unused-vars

    this.currentYear = this.$moment().year()
    this.currentMonth = this.$moment().month() + 1
    this.setFilterDataFromNow()
    this.fetchReports()
  },
  methods: {
    setFilterDataFromNow() {
      this.filterData.start_month = this.currentMonth
      this.filterData.start_year = this.currentYear
      this.filterData.end_year = this.currentYear
      this.filterData.end_month = this.currentMonth
    },
    customPeriodSelected(periodData) {
      this.filterData.start_year = periodData.start_year
      this.filterData.end_year = periodData.end_year
      this.filterData.start_month = periodData.start_month
      this.filterData.end_month = periodData.end_month
      this.fetchReports()
    },
    showDatePeriodSelector() {
      const datePeriodData = {
        start_year: this.filterData.start_year,
        start_month: this.filterData.start_month,
        end_year: this.filterData.end_year,
        end_month: this.filterData.end_month,

      }
      this.$refs.refDatePeriodSelector.showEditor(datePeriodData)
    },
    fetchReports() {
      this.pageIsLoading = true
      store
        .dispatch('app-reports/fetchSalesByProduct', this.filterData)//
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.reportsData.salesByProduct = response.data.data
          this.yearOptions = []
          // eslint-disable-next-line no-plusplus
          for (let i = this.reportsData.salesByProduct.invoice_years.min_invoice_year; i <= this.reportsData.salesByProduct.invoice_years.max_invoice_year; i++) {
            this.yearOptions.push(i)
          }
           if (this.yearOptions.length === 0) {
            const d = new Date()
            this.yearOptions.push(d.getFullYear())
          }
          this.pageIsLoading = false
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    resetForm() {
      this.reportsData = JSON.parse(JSON.stringify(this.blankFilterData))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
