<template>
  <div>
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />

    <b-card>

      <b-overlay
        id="overlay-background"
        :show="pageIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <b-card-header v-if="reportsData.salesByDate">

          <div>
            <h4>Tax year  {{ reportsData.salesByDate.year }} sales</h4>
            <h5>{{ reportTotals.count }} Invoices</h5>
            <h1 class="font-weight-bolder">
              {{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(reportTotals.total) }}
            </h1>
          </div>
          <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <b-form-select
              v-model="selectedFilterYear"
              :options="yearOptions"
              size="lg"
              class="mt-1"
            />
          </div>

        </b-card-header>
        <b-row v-if="reportsData.salesByDate">
          <b-col class="mt-2" />
          <b-col md="12">
            <vue-apex-charts
              type="bar"
              height="300"
              :options="salesChart.chartOptions"
              :series="salesChart.series"
            />
          </b-col>
          <b-col md="12">
            <b-table
              foot-clone
              responsive
              :fields="tableColumns"
              :items="reportsData.salesByDate.sales_by_date"
            >
              <template #cell(invoice_month)="data">
                <span> {{ $moment.months(data.item.invoice_month - 1) }}</span>
              </template>
              <template #cell(subtotal)="data">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(data.item.subtotal) }}</span>
              </template>
              <template #cell(tax_total)="data">
                <span> {{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(data.item.tax_total) }}</span>
              </template>
              <template #cell(discount_total)="data">
                <span> {{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(data.item.discount_total) }}</span>
              </template>
              <template #cell(total)="data">
                <span> {{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(data.item.total) }}</span>
              </template>
              <template #cell(paid_amount)="data">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(data.item.paid_amount) }}</span>
              </template>

              <!-- TABLE FOOTER -->
              <template v-slot:foot(invoice_month)="">
                <span>Total</span>
              </template>
              <template v-slot:foot(subtotal)="">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(reportTotals.subtotal) }}</span>
              </template>
              <template v-slot:foot(tax_total)="">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(reportTotals.tax_total) }}</span>
              </template>
              <template v-slot:foot(discount_total)="">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(reportTotals.discount_total) }}</span>
              </template>
              <template v-slot:foot(total)="">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(reportTotals.total) }}</span>
              </template>
              <template v-slot:foot(paid_amount)="">
                <span>{{ reportsData.salesByDate.currency_code }} {{ $staticParams.formatCurrency(reportTotals.paid_amount) }}</span>
              </template>
              <template v-slot:foot(count)="">
                <span>{{ reportTotals.count }}</span>
              </template>
              <!-- TABLE FOOTER END -->

            </b-table>
          </b-col>

        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BFormSelect, BOverlay, BSpinner, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required, alphaNum, email } from '@validations'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BOverlay,
    BFormSelect,
    BTable,
    BCard,
    BCardHeader,
    BSpinner,
    BCol,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlayMsg: 'Loading...',
      pageIsLoading: true,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Product Updated',
      errorData: null,
      yearOptions: [],
      selectedFilterYear: 0,
      blankFilterData: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        cost: 0,
        enable_taxes: true,
        unit_id: 0,
      },
      reportsData: {
        salesByDate: null,
      },
      tableColumns: [
        { key: 'invoice_month', label: 'MONTH', sortable: true },
        { key: 'subtotal', label: 'SUBTOTAL', sortable: true },
        { key: 'tax_total', label: 'TAX', sortable: true },
        { key: 'discount_total', label: 'DISCOUNT', sortable: true },
        { key: 'total', label: 'TOTAL', sortable: true },
        { key: 'paid_amount', label: 'AMOUNT PAID', sortable: true },
        { key: 'count', label: 'INVOICE COUNT', sortable: true },
      ],

    }
  },
  computed: {
    salesChart() {
      const seriesData = []
      const categoriesData = []
      this.reportsData.salesByDate.sales_by_date.forEach(val => {
        categoriesData.push(this.$moment.months(val.invoice_month - 1))
        seriesData.push(val.total)
      })
      const currencyCode = this.reportsData.salesByDate.currency_code
      const staticParams = this.$staticParams
      const chartData = {
        series: [
          {
            name: 'Apple',
            data: seriesData,
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#826af9'],
          plotOptions: {
            bar: {
              columnWidth: '50%',
              colors: {
                backgroundBarColors: [
                ],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                currencyCode
              } ${
                staticParams.formatCurrency(data.series[data.seriesIndex][data.dataPointIndex])
              }</span></div>`
            },
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: categoriesData,
          },
          yaxis: {
            show: false,
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      }

      return chartData
    },

    reportTotals() {
      const totals = {
        count: 0,
        total: 0,
        tax_total: 0,
        discount_total: 0,
        subtotal: 0,
        paid_amount: 0,

      }
      if (this.reportsData.salesByDate) {
        this.reportsData.salesByDate.sales_by_date.forEach(val => {
          totals.total += val.total
          totals.count += val.count
          totals.tax_total += val.tax_total
          totals.discount_total += val.discount_total
          totals.subtotal += val.subtotal
          totals.paid_amount += val.paid_amount
        })
      }
      return totals
    },
  },
  watch: {
    selectedFilterYear(val) {
      this.fetchReports(val)
    },
  },
  created() {
    const thisYear = this.$moment().year()

    this.selectedFilterYear = thisYear
  },
  methods: {
    fetchReports(taxYear) {
      this.pageIsLoading = true
      store
        .dispatch('app-reports/fetchSalesByDate', { currency_code: this.$user().currency.currency_code, tax_year: taxYear })//
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.reportsData.salesByDate = response.data.data
          this.yearOptions = []
          // eslint-disable-next-line no-plusplus
          for (let i = this.reportsData.salesByDate.invoice_years.min_invoice_year; i <= this.reportsData.salesByDate.invoice_years.max_invoice_year; i++) {
            this.yearOptions.push(i)
          }
           if (this.yearOptions.length === 0) {
            const d = new Date()
            this.yearOptions.push(d.getFullYear())
          }
          this.pageIsLoading = false
        })
        .catch(error => {
          this.pageIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    resetForm() {
      this.reportsData = JSON.parse(JSON.stringify(this.blankFilterData))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
