<template>
  <b-modal
    id="periodSelectorModal"
    ref="periodSelectorModal"
    title="Set custom time period"
    ok-only
    ok-title="Apply"

    @ok="saveData"
  >
    <div v-if="periodData">
      <!-- form -->

      <b-row>
        <b-col
          md="12"
          lg="12"
        >
          <b-form
            class="p-2"
          >
            <!-- start year and month -->
            <b-row>
              <b-col>
                <b-form-group
                  class="mb-2"
                  label="Start year"
                  label-for="start-year"
                >

                  <b-form-select
                    id="start-year"
                    v-model="periodData.start_year"
                    :options="yearOptions"
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="mb-2"
                  label="Start month"
                  label-for="start-month"
                >

                  <b-form-select
                    id="start-month"
                    v-model="periodData.start_month"
                    :options="monthOptions"
                    size="lg"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- start year and month -->
            <!-- end year and month -->
            <b-row>
              <b-col>
                <b-form-group
                  class="mb-2"
                  label="End year"
                  label-for="end-year"
                >

                  <b-form-select
                    id="end-year"
                    v-model="periodData.end_year"
                    :options="yearOptions"
                    size="lg"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="mb-2"
                  label="End month"
                  label-for="end-month"
                >

                  <b-form-select
                    id="end-month"
                    v-model="periodData.end_month"
                    :options="monthOptions"
                    size="lg"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- end year and month -->
          </b-form>

        </b-col>

      </b-row>

    </div>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BModal, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    yearOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      monthOptions: [],
      blankPeriodData: {
        start_year: 0,
        start_month: 1,
        end_year: 0,
        end_month: 0,
      },
      periodData: null,
    }
  },

  created() {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 12; i++) {
      this.monthOptions.push({ text: this.$moment.months(i - 1), value: i })
    }
    this.resetForm()
  },
  methods: {

    showEditor(periodData) {
      if (periodData == null) {
        this.resetForm()
      } else {
        this.periodData.start_year = periodData.start_year
        this.periodData.start_month = periodData.start_month
        this.periodData.end_year = periodData.end_year
        this.periodData.end_month = periodData.end_month
      }
      this.$refs.periodSelectorModal.show()
    },
    closeEditor() {
      this.$refs.periodSelectorModal.hide()
    },

    cancel() {
      this.$emit('closed')
    },
    deleteData() {

    },
    saveData() {
      this.$emit('saved', this.periodData)
    },
    resetForm() {
      this.periodData = JSON.parse(JSON.stringify(this.blankPeriodData))
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
