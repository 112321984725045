import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // REPORT DASHBOARD
    fetchUnpaidCustomers(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports-unpaid-customers', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchYearlyInvoiceReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports-yearly-invoices', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // END REPORT DASHBOARD
    fetchSalesByDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports-sales-by-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalesByClient(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports-sales-by-client', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalesByJournal(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports-sales-by-journal', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalesByProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports-sales-by-product', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
