<template>

  <!-- form -->
  <div>
    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-row class="p-1">
      <b-col
        md="12"
        lg="612"
      >
        <b-tabs>
          <b-tab title="Dashboard">
            <report-dashboard />
          </b-tab>
          <b-tab title="Report list">
            <b-tabs
              v-model="activeReportListTab"
              vertical
              nav-wrapper-class="nav-vertical"
            >
              <b-tab title="Sales by date">
                <sales-by-date v-if="activeReportListTab==0" />
              </b-tab>
              <b-tab title="Sales by client">
                <sales-by-client v-if="activeReportListTab==1" />
              </b-tab>
              <b-tab title="Sales by product">
                <sales-by-product v-if="activeReportListTab==2" />
              </b-tab>
              <b-tab title="Sales by journal">
                <sales-by-journal v-if="activeReportListTab==3" />
              </b-tab>
            </b-tabs>

          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required, alphaNum, email } from '@validations'
import {
  onUnmounted,
} from '@vue/composition-api'
import reportStoreModule from './reportStoreModule'
import reportDashboard from './ReportDashboard.vue'
import salesByDate from './report-list/SalesByDate.vue'
import salesByClient from './report-list/SalesByClient.vue'
import salesByProduct from './report-list/SalesByProduct.vue'
import salesByJournal from './report-list/SalesByJournal.vue'

export default {
  components: {
    BRow,
    reportDashboard,
    BCol,
    BTabs,
    salesByJournal,
    BTab,
    salesByProduct,
    salesByClient,
    salesByDate,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeReportListTab: 0,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Product Updated',
      errorData: null,
      blankFilterData: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        cost: 0,
        enable_taxes: true,
        unit_id: 0,
      },
      productData: null,
    }
  },
  created() {
    this.resetForm()

    let productId = 0

    this.productData.id = productId

    if (this.$router.currentRoute.params.id) {
      productId = this.$router.currentRoute.params.id
      this.fetchProduct(productId)
    }
    // console.log(this.productData)
  },
  methods: {
    fetchProduct(id) {
      store
        .dispatch('app-product/fetchSingleProduct', { id })//
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.productData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },

    resetForm() {
      this.productData = JSON.parse(JSON.stringify(this.blankFilterData))
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
