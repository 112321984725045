<template>
  <div>
    <!-- form -->

    <status-alert
      :is-success="isSuccess"
      :error-data="errorData"
      :success-msg="successMsg"
    />
    <b-card>

      <b-overlay
        id="overlay-unpaid-customers"
        :show="unpaidCustomersIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <b-row>
          <b-col
            v-if="reportsData.unpaidCustomers"
            md="12"
            lg="12"
          >
            <h4>Total unpaid balance</h4>
            <h1 class="font-weight-bolder">
              {{ reportsData.unpaidCustomers.currency_code }} {{ $staticParams.formatCurrency(unpaidCustomersTotalAmount) }}
            </h1>
            <b-list-group

              class="mt-2"
              flush
            >
              <b-list-group-item
                v-for="item,index in reportsData.unpaidCustomers.unpaid_customers"
                :key="index"
                button
              >
                <div class="d-flex justify-content-between align-items-center">
                  <h4>{{ item.client_name }}</h4>
                  <h3 class="font-weight-bolder">
                    {{ reportsData.unpaidCustomers.currency_code }} {{ $staticParams.formatCurrency(item.total) }}
                  </h3>
                </div>
              </b-list-group-item>

            </b-list-group>

          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
    <b-card>

      <b-overlay
        id="overlay-background"
        :show="yearlyInvoiceReportIsLoading"
        variant="light"
        opacity="0.85"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <b-card

            class="text-center"
          >

            <h2>
              <b-spinner

                class="mr-1"
                label="Loading"
              />
              {{ overlayMsg }}
            </h2>

          </b-card>
        </template>
        <b-card-header v-if="reportsData.yearlyInvoiceReport">

          <div>
            <h4>Tax year start {{ reportsData.yearlyInvoiceReport.year }} sales</h4>
            <h5>{{ yearlyInvoiceTotals.count }} Invoices</h5>
            <h1 class="font-weight-bolder">
              {{ reportsData.yearlyInvoiceReport.currency_code }} {{ $staticParams.formatCurrency(yearlyInvoiceTotals.total) }}
            </h1>
          </div>
          <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
            <b-form-select
              v-model="selectedFilterYear"
              :options="yearOptions"
              size="lg"
              class="mt-1"
            />
          </div>

        </b-card-header>
        <b-row v-if="reportsData.yearlyInvoiceReport">
          <b-col class="mt-2" />
          <b-col md="12">
            <vue-apex-charts
              type="bar"
              height="300"
              :options="salesChart.chartOptions"
              :series="salesChart.series"
            />
          </b-col>
          <b-col md="12">
            <b-list-group

              flush
            >
              <b-list-group-item
                v-for="item,index in reportsData.yearlyInvoiceReport.yearly_invoice_report"
                :key="index"

                button
              >
                <div class="d-flex justify-content-between align-items-center">
                  <h4>{{ $moment.months(item.invoice_month - 1) }} {{ reportsData.yearlyInvoiceReport.year }} - {{ item.count }} Invoices</h4>
                  <h3 class="font-weight-bolder">
                    {{ reportsData.yearlyInvoiceReport.currency_code }} {{ $staticParams.formatCurrency(item.total) }}
                  </h3>
                </div>
              </b-list-group-item>

            </b-list-group>
          </b-col>

        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BCard, BCardHeader, BFormSelect, BOverlay, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required, alphaNum, email } from '@validations'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BRow,
    BOverlay,
    BListGroup,
    BFormSelect,
    BCard,
    BListGroupItem,
    BCardHeader,
    BSpinner,
    BCol,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlayMsg: 'Loading...',
      unpaidCustomersIsLoading: true,
      yearlyInvoiceReportIsLoading: true,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Product Updated',
      errorData: null,
      yearOptions: [],
      selectedFilterYear: 0,
      blankFilterData: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        cost: 0,
        enable_taxes: true,
        unit_id: 0,
      },
      reportsData: {
        unpaidCustomers: null,
        yearlyInvoiceReport: null,
      },

    }
  },
  computed: {
    salesChart() {
      const seriesData = []
      const categoriesData = []
      this.reportsData.yearlyInvoiceReport.yearly_invoice_report.forEach(val => {
        categoriesData.push(this.$moment.months(val.invoice_month - 1))
        seriesData.push(val.total)
      })
      const currencyCode = this.reportsData.yearlyInvoiceReport.currency_code
      const staticParams = this.$staticParams
      const chartData = {
        series: [
          {
            name: 'Apple',
            data: seriesData,
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ['#826af9'],
          plotOptions: {
            bar: {
              columnWidth: '50%',
              colors: {
                backgroundBarColors: [
                ],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                currencyCode
              } ${
                staticParams.formatCurrency(data.series[data.seriesIndex][data.dataPointIndex])
              }</span></div>`
            },
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: categoriesData,
          },
          yaxis: {
            show: false,
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      }
      return chartData
    },

    unpaidCustomersTotalAmount() {
      let totalAmount = 0
      if (this.reportsData.unpaidCustomers) {
        this.reportsData.unpaidCustomers.unpaid_customers.forEach(val => {
          totalAmount += val.total
        })
      }
      return totalAmount
    },
    yearlyInvoiceTotals() {
      const totals = {
        count: 0,
        total: 0,
      }
      if (this.reportsData.yearlyInvoiceReport) {
        this.reportsData.yearlyInvoiceReport.yearly_invoice_report.forEach(val => {
          totals.total += val.total
          totals.count += val.count
        })
      }
      return totals
    },
  },
  watch: {
    selectedFilterYear(val) {
      this.fetchYearlyInvoiceReport(val)
    },
  },
  created() {
    const thisYear = this.$moment().year()

    this.selectedFilterYear = thisYear

    //  this.resetForm()
    this.fetchUnpaidCustomers()
    // console.log(this.reportsData)
  },
  methods: {
    fetchUnpaidCustomers() {
      this.unpaidCustomersIsLoading = true
      store
        .dispatch('app-reports/fetchUnpaidCustomers', { currency_code: this.$user().currency.currency_code })//
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.reportsData.unpaidCustomers = response.data.data
          this.unpaidCustomersIsLoading = false
        })
        .catch(error => {
          this.unpaidCustomersIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    fetchYearlyInvoiceReport(taxYear) {
      this.yearlyInvoiceReportIsLoading = true
      store
        .dispatch('app-reports/fetchYearlyInvoiceReports', { currency_code: this.$user().currency.currency_code, tax_year: taxYear })//
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.reportsData.yearlyInvoiceReport = response.data.data
          this.yearOptions = []
          // eslint-disable-next-line no-plusplus
          for (let i = this.reportsData.yearlyInvoiceReport.invoice_years.min_invoice_year; i <= this.reportsData.yearlyInvoiceReport.invoice_years.max_invoice_year; i++) {
            this.yearOptions.push(i)
          }
          if (this.yearOptions.length === 0) {
            const d = new Date()
            this.yearOptions.push(d.getFullYear())
          }
          this.yearlyInvoiceReportIsLoading = false
        })
        .catch(error => {
          this.yearlyInvoiceReportIsLoading = false
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    resetForm() {
      this.reportsData = JSON.parse(JSON.stringify(this.blankFilterData))
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
